import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Element } from 'react-scroll';
import { useSelector } from 'react-redux';
import { get, post } from '../helpers/helper_api';
import Header from '../component/Header';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function Home() {

    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.user.data);
    const [plan, setPlan] = useState(undefined)
    const [video1, setVideo1] = useState(false);
    const [video2, setVideo2] = useState(false);
    const [video3, setVideo3] = useState(false);
    const [video4, setVideo4] = useState(false);
    const toggle = () => setVideo1(!video1);
    const toggle1 = () => setVideo2(!video2);
    const toggle2 = () => setVideo3(!video3);
    const toggle3 = () => setVideo4(!video4);

    useEffect(() => {

        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    useEffect(() => {
        getPlan()
    }, [])


    const getPlan = () => {

        get('plan')
            .then((res) => {
                // console.log('plan res', res)
                if (res?.statusCode == 200) {
                    setPlan(res?.data)
                }
            }).catch(err => {
                console.log('errer', err)
            })
    }

    const navigator = useNavigate();


    const handleValidSubmit = (e, v) => {
        setLoading(true)
        let body = {
            ...v,
        }
        post('enquiry', body)
            .then((res) => {
                // console.log('res', res);
                setLoading(false)
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    window.scrollTo(0, 0);
                }
                if (res?.statusCode == 208) {
                    toast.success(res?.message)
                }
                if (res?.statusCode == 404) {
                    toast.success(res?.error)
                }
            }).catch(err => {
                setLoading(false)
                console.log('errr', err)
            })


    }

    return (
        <div>
            {
                loading ? <div class="se-pre-con1"></div> : null
            }

            <Header />
            <Modal centered isOpen={video1} className='video-modal' >
                <ModalBody>
                    <button className='close_button' onClick={() => setVideo1(false)}>X</button>
                    <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/XKOOHFdwRIU"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </ModalBody>

            </Modal>
            <Modal centered isOpen={video2} className='video-modal' >
                <ModalBody>
                    <button className='close_button' onClick={() => setVideo2(false)}>X</button>
                    <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/dyBU3Qob72c"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </ModalBody>

            </Modal>
            <Modal centered isOpen={video3} className='video-modal' >
                <ModalBody>
                    <button className='close_button' onClick={() => setVideo3(false)}>X</button>
                    <iframe
                        width="100%"
                        // height="480"
                        src="https://www.youtube.com/embed/pEjOTEQWkCQ"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </ModalBody>

            </Modal>
            <Modal centered isOpen={video4} className='video-modal video-modal_new' >
                <ModalBody>
                    <button className='close_button' onClick={() => setVideo4(false)}>X</button>
                    <iframe width="100%" src="https://www.youtube.com/embed/cEjfmqt8Fuk?si=9LelIiCLwK5k5W1X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </ModalBody>

            </Modal>
            <Element name="home">
                <div id='home' class="banner-style-three-area overflow-hidden" style={{ backgroundImage: `url(${require('../assets/img/shape/banner-5.jpg')})` }}>

                    <div class="banner-style-three pt-100 pt-md-120 pt-xs-60">
                        <div class="container">
                            <div class="content">
                                <div class="row align-center">
                                    <div class="col-xl-6 col-lg-6 pr-50 pr-md-15 pr-xs-15 mt--80 mt-md-0 mt-xs-0">
                                        <div class="information">
                                            <h2 class="wow fadeInUp" data-wow-delay="500ms" data-wow-duration="400ms">Empower Your<br />
                                                <strong>Earnings</strong> with Apex Leap</h2>
                                            <p class="wow fadeInUp" data-wow-delay="900ms" data-wow-duration="400ms">
                                                Unlock financial freedom with Apex Leap.
                                            </p>
                                            <p class="wow fadeInUp" data-wow-delay="900ms" data-wow-duration="400ms">
                                                Join our task-based reward system today and start earning effortlessly.
                                            </p>
                                            <div className='app-download-box'>
                                                <a href='https://play.google.com/store/apps/details?id=com.apexleap&hl=en_IN' target='_blank'>
                                                    <img src={require('../assets/img/android.png')} />
                                                </a>
                                                {/* <a href='#'>
                                                    <img src={require('../assets/img/app-store.png')} />
                                                </a> */}
                                            </div>
                                            <div class="newsletter-form banner-button-group wow fadeInDown mt-30" data-wow-delay="1200ms" data-wow-duration="400ms">
                                                <button onClick={() => navigator('/register')} type="submit" name="submit" id="submit">
                                                    <i class="fa fa-paper-plane"></i>Register now!
                                                </button>
                                                <button className='login-btn' onClick={() => navigator('/login')} type="submit" name="submit" id="submit">
                                                    <i class="fa fa-paper-plane"></i>Login
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 pl-60 pl-md-15 pl-xs-15">
                                        <div class="thumb">
                                            <img class="wow fadeInDown" src={require('../assets/img/illustration/2.png')} alt="Thumb" />
                                            <img src={require('../assets/img/illustration/3.png')} alt="Image Not Found" />
                                            <div class="progress-card">
                                                <div class="icon">
                                                    <i class="flaticon-startup-5"></i>
                                                </div>
                                                <div class="info">
                                                    <p>Income Increase</p>
                                                    <h4>+120%</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Element>
            <Element name="about">
                <div class="choose-us-style-one-area default-padding" id="about">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7 choose-us-style-one">
                                <h4 class="sub-title">Why Choose Us</h4>
                                <h2 class="title">We're on a mission <br />to fuel your growth. </h2>
                                <div class="choose-us-thumb mt-50 mt-xs-40">
                                    <img class="wow fadeInUp" src={require('../assets/img/illustration/6.png')} alt="Image Not Found" />
                                    <img class="wow fadeInDown" src={require('../assets/img/illustration/7.png')} alt="Image Not Found" />
                                    <img class="wow fadeInRight" src={require('../assets/img/illustration/5.png')} alt="Image Not Found" />
                                </div>
                            </div>
                            <div class="col-lg-5 pl-70 pl-md-15 pl-xs-15 choose-us-style-one">
                                <div class="curve-text" style={{ cursor: 'pointer' }} onClick={toggle3}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" version="1.1">
                                        <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
                                        <text>
                                            <textPath href="#textPath">How It Works (Play Video) </textPath>
                                        </text>
                                    </svg>
                                    <a href="javascript:void(0);" ><i class="fas fa-play"></i></a>
                                </div>
                                <ul class="feature-process mt-75 mt-xs-0">
                                    <li class="wow fadeInLeft">
                                        <div class="info">
                                            <a href="#">Easy to Register</a>
                                            <p>
                                                Sign up in minutes with your details, complete OTP verification, and start your journey to financial empowerment with Apex Leap.
                                            </p>
                                        </div>
                                        <div class="link">
                                            <a href="#"><i class="fas fa-long-arrow-right"></i></a>
                                        </div>
                                    </li>
                                    <li class="wow fadeInLeft" data-wow-delay="500ms">
                                        <div class="info">
                                            <a href="#">Tasks Based Earnings</a>
                                            <p>
                                                Complete weekly tasks and earn effortlessly. Join Apex Leap for a simple, effective way to boost your earnings.
                                            </p>
                                        </div>
                                        <div class="link">
                                            <a href="#"><i class="fas fa-long-arrow-right"></i></a>
                                        </div>
                                    </li>
                                    <li class="wow fadeInLeft" data-wow-delay="900ms">
                                        <div class="info">
                                            <a href="#">Upgrade Plans Anytime</a>
                                            <p>
                                                Easily switch plans anytime to match your goals. Enjoy flexibility and continuous growth with Apex Leap's adaptable plan options.
                                            </p>
                                        </div>
                                        <div class="link">
                                            <a href="#"><i class="fas fa-long-arrow-right"></i></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            <div class="about-style-one-area bg-gray pt-100 ">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-12">
                            <div class="about-style-one bg-dark text-light" style={{ backgroundImage: `url(${require('../assets/img/shape/7.png')})` }}>
                                <ul class="check-list-item">
                                    <li>
                                        <h5>Empower Your Financial Growth</h5>
                                        <p>
                                            Join Apex Leap to complete tasks, earn rewards, and unlock your financial potential.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Be Independent with Apex Leap</h5>
                                        <p>
                                            Our platform empowers you to earn and grow effortlessly.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-12">
                            <div class="about-style-one">
                                <h2 class="title pl-120 pl-md-0 pl-xs-0 mb-70 mb-md-40 mb-xs-30 mt-md-50 mt-xs-30">Providing the best service <br /> In Task-based<strong> Earning</strong></h2>
                                <div class="thumb bg-cover" style={{ backgroundImage: 'url(assets/img/2440x1578.png)' }}>
                                    <div class="video-bg-live">
                                        <img src={require('../assets/img/abc.jpg')} />
                                    </div>
                                    {/* <a href="#" class="popup-youtube video-play-button with-text mt-20">
                                        <div class="effect"></div>
                                        <span><i class="fas fa-play"></i> OUR STORY</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Element name="plan">
                <div class="pricing-area pricing-gird default-padding bottom-less bg-gray" id='plan'>
                    <div class="container">
                        <h4 class="sub-title">What we offer</h4>
                        <h2 class="title mb-30">Select the Plan That <br />Suits Your Goals and Needs</h2>
                        <div class="pricing-style-two-items">
                            <div class="row">
                                {
                                    plan?.map((item, index) => {
                                        return (
                                            <div key={index} class="col-xl-4 col-md-6 mb-30">
                                                <div class="pricing-style-two active">
                                                    <div class="pricing-header">
                                                        <h4>{item?.title}</h4>
                                                        <p>
                                                            {item?.tagLine}
                                                        </p>
                                                    </div>
                                                    <div class="pricing-content">
                                                        <div class="price">
                                                            <h2><sup>₹</sup>{item?.amount}/-<sup>+ {item?.gst}% GST</sup></h2>
                                                        </div>
                                                        <ul>
                                                            {
                                                                item?.bullets?.map((item1, index1) => {
                                                                    return (
                                                                        <li key={index1}><i class="fas fa-check-circle"></i> {item1}</li>
                                                                    )
                                                                })
                                                            }


                                                        </ul>
                                                        <Link class="btn mt-30 btn-sm btn-dark effect" to="/register">Select Plan</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            <Element>
                <div
                    className=" default-padding pb-0 bg-gray bg-cover"
                    style={{
                        backgroundImage: "url('assets/img/shape/banner-3.jpg')",
                    }}
                >
                    <div className="container">
                        <div className="breadcrumb-item pb-120">
                            <div className="breadcrum-shape">
                                <img src="assets/img/shape/16.png" alt="Image Not Found" />
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <h4 class="sub-title">Earn More, Do More</h4>
                                    <h2 class="title mb-30"> Simple Tasks, Real Rewards <br />
                                        with Apex-Leap!</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="default-padding box-layout overflow-hidden bottom-less services-style-one-area bg-gray bg-cover"
                    style={{
                        backgroundImage: "url('assets/img/shape/banner-2.jpg')",
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
                                <div className="services-style-one services-style-one_video_popup" onClick={toggle}>
                                    <img src={require('../assets/img/thumb_image_1.jpg')} />

                                    {/* <iframe
                                                width="270"
                                                height="480"
                                                src="https://www.youtube.com/embed/XKOOHFdwRIU"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerPolicy="strict-origin-when-cross-origin"
                                                allowFullScreen
                                            ></iframe> */}

                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
                                <div className="services-style-one services-style-one_video_popup" onClick={toggle1}>
                                    <img src={require('../assets/img/thumb_image_3.jpg')} />

                                    {/* <p>
                                        <iframe
                                            width="270"
                                            height="480"
                                            src="https://www.youtube.com/embed/dyBU3Qob72c"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </p> */}
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 mb-30">
                                <div className="services-style-one services-style-one_video_popup" onClick={toggle2}>
                                    <img src={require('../assets/img/thumb_image_2.jpg')} />
                                    {/* <p>
                                        <iframe
                                            width="270"
                                            height="480"
                                            src="https://www.youtube.com/embed/pEjOTEQWkCQ"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element >
            <div class="testimonial-style-two-area mt-75 overflow-hidden bg-gradient text-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="testimonial-style-two-box default-padding">
                                <div class="star-shape">
                                    <img src={require('../assets/img/shape/21.png')} alt="Image Not Found" />
                                </div>
                                <img src={require('../assets/img/shape/quote-big.png')} alt="Image Not Found" />
                                <div class="site-heaidng site_heaidng">
                                    <h2 class="title">What people say</h2>
                                    <div class="testimonial-two-swiper-nav">

                                        <div class="testimonial-two-pagination"></div>
                                        <div class="testimonial-two-button-prev"></div>
                                        <div class="testimonial-two-button-next"></div>
                                    </div>
                                </div>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}


                                    className="mySwiper testimonial-style-two-carousel swiper"
                                >
                                    <SwiperSlide>
                                        <div class="testimonial-style-two">
                                            <div class="item">
                                                <div class="content">
                                                    <p>
                                                        "I joined Apex Leap a few months ago and it has been an incredible journey. The weekly tasks are straightforward and rewarding. The best part is the prompt payment credited to my wallet. Apex Leap has made it so easy to earn extra money from home. Highly recommend it!"
                                                    </p>
                                                </div>
                                                <div class="provider">
                                                    <div class="info">
                                                        <h4>Priya Sharma</h4>
                                                        <span>Mumbai</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style-two">
                                            <div class="item">
                                                <div class="content">
                                                    <p>
                                                        "As a college student, I was looking for ways to earn some pocket money without compromising on my studies. Apex Leap came as a blessing. The registration process was simple, and the tasks are easy to manage. The team is very supportive and the payment system is reliable. I am thrilled to be a part of this platform!"
                                                    </p>
                                                </div>
                                                <div class="provider">
                                                    <div class="info">
                                                        <h4>Rohit Verma</h4>
                                                        <span>Delhi</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style-two">
                                            <div class="item">
                                                <div class="content">
                                                    <p>
                                                        "I was skeptical about online earning platforms, but Apex Leap proved to be trustworthy and efficient. The task assignments are clear, and the admin team is always available to assist. I appreciate the timely payments and the user-friendly interface. It's a great way to supplement my income."
                                                    </p>
                                                </div>
                                                <div class="provider">
                                                    <div class="info">
                                                        <h4>Sanjana Reddy</h4>
                                                        <span>Hyderabad</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style-two">
                                            <div class="item">
                                                <div class="content">
                                                    <p>
                                                        "Apex Leap has been a game-changer for me. The registration and KYC process was smooth, and I was quickly onboarded. The tasks are interesting and the payment system is seamless. I love the transparency and efficiency of the platform. Kudos to the team!"
                                                    </p>
                                                </div>
                                                <div class="provider">
                                                    <div class="info">
                                                        <h4>Karan Patel</h4>
                                                        <span>Ahmedabad</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div class="testimonial-style-two">
                                            <div class="item">
                                                <div class="content">
                                                    <p>
                                                        "Joining Apex Leap has been one of the best decisions. The tasks are easy to complete, and I receive payments directly to my wallet without any hassle. The platform is secure and reliable. It's perfect for anyone looking to earn some extra money. Highly satisfied!"
                                                    </p>
                                                </div>
                                                <div class="provider">
                                                    <div class="info">
                                                        <h4>Neha Kapoor</h4>
                                                        <span>Bangalore</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Element name='howweworks'>

                <div class="process-style-one-area text-center default-padding-bottom pt-100 " id='howweworks'>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2">
                                <div class="site-heading text-center">
                                    <h5 class="sub-title">How we work</h5>
                                    <h2 class="title">Brighter future when <br /> strategically more prepared</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">

                            <div class="col-xl-3 col-lg-6 process-style-one">
                                <div class="item">
                                    <div class="icon">
                                        <i class="flaticon-cyber-security"></i>
                                    </div>
                                    <div class="point">
                                        <span>01</span>
                                    </div>
                                    <h4>Register</h4>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-6 process-style-one">
                                <div class="item">
                                    <div class="icon">
                                        <i class="flaticon-paper"></i>
                                    </div>
                                    <div class="content">
                                        <div class="point">
                                            <span>02</span>
                                        </div>
                                        <h4>Peform the Task</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-6 process-style-one">
                                <div class="item">
                                    <div class="icon">
                                        <i class="flaticon-start-up"></i>
                                    </div>
                                    <div class="point">
                                        <span>03</span>
                                    </div>
                                    <h4>Submit the Work</h4>
                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-6 process-style-one">
                                <div class="item">
                                    <div class="icon">
                                        <i class="flaticon-support-1"></i>
                                    </div>
                                    <div class="point">
                                        <span>04</span>
                                    </div>
                                    <h4>Get Paid</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Element>
            <div className='download-section '>
                <div class="site-heading text-center">
                    <h5 class="sub-title">Download Now</h5>
                    <h2 class="title">Apex-Leap Mobile App</h2>
                </div>
                <p>Take the leap towards achieving your dreams. <br />Download Apex-Leap Mobile App now and start your journey to peak performance! </p>
                <a href='https://play.google.com/store/apps/details?id=com.apexleap&hl=en_IN' target='_blank'>
                    <img src={require('../assets/img/android.png')} />
                </a>
            </div>
            <Element name='contact'>
                <div class="contact-area overflow-hidden default-padding bg-gray" id='contact'>
                    <div class="shape-right-bottom">
                        <img src={require('../assets/img/shape/18.png')} alt="Shape" />
                    </div>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-tact-stye-one col-lg-5">
                                <div class="contact-style-one-info">
                                    <div class="mb-40">
                                        <h2>Contact Information</h2>
                                        <p>
                                            We value your feedback and inquiries. <br />Our team is here to assist you and ensure your experience with us is as smooth and satisfying as possible.
                                        </p>
                                    </div>
                                    <ul class="contact-address">
                                        <li class="wow fadeInUp">
                                            <div class="content">
                                                <h4 class="title">Phone</h4>
                                                <a href="tel:918000505059">+91 80005 05059</a>
                                            </div>
                                        </li>
                                        <li class="wow fadeInUp" data-wow-delay="300ms">
                                            <div class="info">
                                                <h4 class="title">Location</h4>
                                                <p>
                                                    Work skill
                                                    Sector 38, Gurgaon, Haryana - 122022
                                                </p>
                                            </div>
                                        </li>
                                        <li class="wow fadeInUp" data-wow-delay="500ms">
                                            <div class="info">
                                                <h4 class="title">Official Email</h4>
                                                <a href="mailto:info@apex-leap.com">info@apex-leap.com</a>
                                            </div>
                                        </li>
                                        <li class="wow fadeInUp" data-wow-delay="700ms">
                                            <div class="info">
                                                <h4 class="title">Follow Us</h4>
                                                <ul class="social-link">
                                                    <li>
                                                        <a class="facebook" href="https://www.facebook.com/apexleap07/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                                    </li>
                                                    <li>
                                                        <a class="twitter" href="https://www.instagram.com/apex.leap07/" target="_blank"><i class="fab fa-instagram"></i></a>
                                                    </li>
                                                    <li>
                                                        <a class="pinterest" href="https://www.youtube.com/@apex-leap" target="_blank"><i class="fab fa-youtube"></i></a>
                                                    </li>
                                                    <li>
                                                        <a class="linkedin" href="https://www.linkedin.com/company/apex-leap" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-tact-stye-one col-lg-6 offset-lg-1">
                                <div class="contact-form-style-one">
                                    <h4 class="sub-title">Have Questions?</h4>
                                    <h2 class="title">Send us a Massage</h2>
                                    <AvForm onValidSubmit={handleValidSubmit} class="contact-form contact-form">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <AvField class="form-control" id="name" name="name" placeholder="Name" type="text" required />
                                                    <span class="alert-error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <AvField class="form-control" id="email" name="email" placeholder="Email*" type="email" required />
                                                    <span class="alert-error"></span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <AvField class="form-control" id="mobile" name="mobile" placeholder="Phone" type="text" required />
                                                    <span class="alert-error"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group comments">
                                                    <AvField class="form-control" id="msg" name="msg" placeholder="Massage*" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <button type="submit" name="submit" >
                                                    <i class="fa fa-paper-plane"></i> Get in Touch
                                                </button>
                                            </div>
                                        </div>

                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>

        </div >
    )
}
