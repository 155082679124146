import React, { useState } from 'react';
import Header from '../component/Header';


export default function About() {
    return (
        <React.Fragment>

            <div className='about-container'>
                <Header />
                <div className='main-section-box'>
                    <h1 className='heading_box'>
                        ABOUT  US
                    </h1>

                    <h3 className='founded'>
                        Apex Group
                    </h3>

                    <span className='leader_heading'>A Journey of Excellence in IT Consulting and Solutions</span>

                    <div className='grow_box'>
                        <p>
                            Apex Group, founded in August 2020, is a rapidly growing IT consulting and solutions company with a strong track record of delivering successful projects for clients in the USA and Australia. The company specializes in areas such as data management and analytics, enterprise resource planning (ERP), data mining, model transformation(MT), and electronic lab systems (E-lab). With a focus on innovation, efficiency, and client satisfaction, Apex Group has quickly earned a reputation for delivering high-quality solutions ahead of schedule.
                        </p>

                        <p>

                            The founding members of Apex Group—Mr. NK Mathur, Mr. HP Singh, and Mr. Abhinav Malhotra—bring decades of combined experience in the IT sector from various global markets. Their leadership and expertise have been instrumental in shaping the company's strategic direction and driving its success in a highly competitive industry.
                        </p>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-lg-4'>
                            <div className='mathur_section '>
                                <h3>
                                    Mr. NK Mathur
                                </h3>

                                <span className='leader_heading'>Visionary Leader and IT Consultant</span>

                                <div className='grow_box'>
                                    <p>
                                        Mr. NK Mathur, the visionary behind Apex Group, spent nine years as CEO of a prominent IT company in the USA, a role he held until 2012. After stepping down from this position, he shifted his focus to freelancing, where he specialized in outsourcing IT projects in the Asian market. Since 2013, Mr. Mathur has successfully delivered 38 large-scale projects, establishing himself as a trusted consultant in the industry. His portfolio spans a range of IT solutions, from data management and analytics to ERP and data mining.

                                        The Covid-19 pandemic brought new challenges to the IT industry, but it also created opportunities for growth and innovation. Recognizing this, Mr. Mathur joined forces with Mr. Singh and Mr. Malhotra to create Apex Group, a company built on their shared vision of delivering cutting-edge IT solutions to clients worldwide.
                                    </p>

                                </div>
                            </div>

                        </div>

                        <div className='col-lg-4'>
                            <div className='mathur_section '>
                                <h3>
                                    Mr. HP Singh

                                </h3>

                                <span className='leader_heading'>
                                    Expertise in Software Development
                                </span>
                                <div className='grow_box'>
                                    <p>
                                        Mr. HP Singh brings a wealth of experience in software development, having worked for two years in a leading software development company in the USA until 2019. His expertise in software engineering and project management has been crucial to Apex Group's ability to deliver complex projects efficiently. Mr. Singh's technical knowledge and hands-on approach ensure that Apex Group's solutions are not only innovative but also practical and tailored to each client’s unique needs.

                                        Since joining Apex Group, Mr. Singh has played a key role in driving the company's success, contributing to the completion of eight major projects for international clients. His background in software development provides the technical foundation needed to deliver high-performance solutions that meet the demands of a fast-evolving industry.
                                    </p>
                                </div>

                            </div>

                        </div>

                        <div className='col-lg-4'>
                            <div className='mathur_section shadows'>
                                <h3>
                                    Mr. Abhinav Malhotra
                                </h3>

                                <span className='leader_heading'>
                                    Global Perspective in IT Solutions
                                </span>
                                <div className='grow_box'>

                                    <p>
                                        Mr. Abhinav Malhotra brings a global perspective to Apex Group, having worked for five years in a software development company in Japan before joining the team in 2018. His experience in international markets, combined with his expertise in software development, has helped Apex Group expand its service offerings and adapt to the diverse needs of its clients. Mr. Malhotra’s deep understanding of global IT trends and technologies enables the company to remain at the forefront of innovation.

                                        Under the leadership of these three industry veterans, Apex Group has successfully delivered eight large projects ahead of schedule, earning the trust of clients in both the USA and Australia. The company's commitment to excellence, innovation, and client satisfaction has set it apart in the competitive IT landscape.

                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='applic_hd'>
                        <h3>
                            Apex Group's
                        </h3>

                        <span className='leader_heading'>
                            Mobile Application and Nationwide Reach
                        </span>
                        <div className='grow_box'>
                            <p>
                                In addition to its consulting and project delivery services, Apex Group has recently launched its own mobile application, designed to provide work opportunities to people across India. This initiative aligns with the company’s mission to leverage technology to create new economic opportunities and empower individuals in the IT sector. Through this mobile platform, Apex Group is expanding its reach and enabling talented professionals from all corners of the country to contribute to its growing portfolio of projects.

                                With its strong foundation, experienced leadership, and commitment to delivering high-quality IT solutions, Apex Group is poised for continued success and expansion in the global IT market.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
