// import axios from "axios";
import AWS from "aws-sdk";
// import accessToken from "./jwt-token-access/accessToken"
//pass new generated access token here
// const token = accessToken
// apply base url for axios
// const API_URL = "http://192.168.1.148:8082/";
// const API_URL = "http://192.168.1.9:8089/";
const API_URL = "https://api.apex-leap.com/";

// const axiosApi = axios.create({
//   baseURL: API_URL,
// })
// axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.interceptors.response.use(
//   response => response,
//   error => Promise.reject(error)
// )
export async function get(url, data) {
  // return await axiosApi.get(url, { ...config }).then(response => response.data)
  return fetch(API_URL + url, {
    method: 'GET',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    // body: JSON.stringify(data)
  }).then(res => res.json())
}
export async function post(url, data) {
  // console.log('post method req', url, data)
  return fetch(API_URL + url, {
    method: 'POST',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
  // .post(API_URL+url, { ...data }, { ...config })
  // .then(response => response.data)
}
export async function put(url, data) {
  // console.log('post method req', url, data)
  return fetch(API_URL + url, {
    method: 'PUT',
    headers: {
      // mode: 'no-cors',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": "Bareer " + data?.token
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}
export async function upload(url, data, token) {
  console.log('upload method req', url, data)
  // for (const [key, value] of data) {
  //   console.log('form data before hitting', `${key}: ${value}\n`);
  // }
  return fetch(API_URL + url, {
    method: 'POST',
    // mode: 'no-cors',
    // headers: {
    //   Authorization: `Bearer ${token}`, // Set the authorization header
    // },
    body: data
  }).then(res => res.json())

}



export async function uploadS3(folder, data, type, onProgress) {
  console.log("process.env ", process.env.REACT_APP_AWS_ACCESS_KEY_ID);
  const s3 = new AWS.S3({
    accessKeyId: 'AKIAQFC27ESZ6A7JJ27U',
    secretAccessKey: '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',
    region: 'ap-south-1',
  });
  const params = {
    Bucket: 'apex-leap',
    Body: data,
    Key: 'image/' + folder + "/" + "." + type,
    ACL: 'public-read',
    // "content-type": type==""
  };
  console.log("bucket params", params);
  const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
  const managedUpload = s3.upload(params, options);
  managedUpload.on('httpUploadProgress', (progressData) => {
    const percentUploaded = Math.round(
      (progressData.loaded / progressData.total) * 100
    );
    onProgress(percentUploaded);
  });
  const resp = await managedUpload.promise();
  console.log('response from s3', resp);
  if (resp)
    return {
      statusCode: 200,
      data: resp.Location,
      message: "SUCCESS"
    }
  else throw "error with img";
}
export async function uploadWithTokan(url, data, token) {
  console.log('upload method req', url, data)
  // for (const [key, value] of data) {
  //   console.log('form data before hitting', `${key}: ${value}\n`);
  // }
  return fetch(API_URL + url, {
    method: 'POST',
    // mode: 'no-cors',
    headers: {
      Authorization: `Bearer ${token}`, // Set the authorization header
      // 'Content-Type': 'multipart/Form-Data',
    },
    body: data
  }).then(res => res.json())
}
export async function del(url, config = {}) {
  // return await axiosApi
  //   .delete(url, { ...config })
  //   .then(response => response.data)
}